.alinha{
    text-align: left;
    padding: 30px;
}
.text-hotel{    
    text-align: center;
}
.h-1{
    height: 100%;
}
.img-hotel{
 
width: 500px;
min-width: 150px !important;
}
.ajusti{
    flex-direction: column;
}
@media (max-width: 768px){
    .img-hotel{
        align-items: center;
        text-align: center;
        width:  350px;
    }
}
@media (max-width: 768px){
    .d-flex{
        padding: 0;
        align-items: center;
        flex-direction:  column;
    }
}
.border{
    box-shadow: 0 0 10px rgba(49, 49, 50, 0.676);
}
.textfinal:hover{
    color: rgb(255, 0, 0);
}

.height-100{
    height: 100vh;
}