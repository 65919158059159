.sobre-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    /* Cor de fundo da tela */
}
.comp{
    text-align: left;
    padding-left: 50px;
    
}
.comp li{
    list-style: circle;
}
.sobre-content {
    max-width: 800px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
}
.height-100{
    height: 100%;
    height: 100vh;
}
ul li{
    list-style: none;
    padding: 0;
}
.pl-2{
    padding-left: 30px;
}
.textfinal{
    color: #0071f8;
}
.pt-6{
    padding: 10px;
}
