.navbar-collapse {
    flex-grow: 0;
}

.bg-header {
    background-color: rgba(249, 249, 249, 0.93);
}

.text-header {
    font-weight: bold;
}
